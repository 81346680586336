var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        {
          staticClass: "advanced__button",
          attrs: { title: "עריכה מתקדמת" },
          on: { click: _vm.showCkEditor }
        },
        [_c("b-icon", { attrs: { pack: "fa", icon: "plus" } })],
        1
      ),
      _vm.editor && !_vm.isCkEditor
        ? _c(
            "div",
            { staticClass: "NewTask__Component__Editor_Inner" },
            [
              _c("editor-content", { attrs: { editor: _vm.editor } }),
              _c(
                "div",
                { staticClass: "NewTask__Component__Editor__Actions" },
                [
                  _c(
                    "button",
                    {
                      staticClass: "editor_button",
                      class: { "is-active": _vm.editor.isActive("bold") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "format-bold" } })],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "editor_button",
                      class: { "is-active": _vm.editor.isActive("italic") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "format-italic" } })],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "editor_button",
                      class: { "is-active": _vm.editor.isActive("underline") },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .run()
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "format-underline" } })],
                    1
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "editor_button",
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .unsetAllMarks()
                            .run()
                        }
                      }
                    },
                    [_c("b-icon", { attrs: { icon: "format-clear" } })],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm.isCkEditor
        ? _c("ckeditor", {
            attrs: { editor: _vm.ckEditor, config: _vm.ckEditorConfig },
            model: {
              value: _vm.ckEditorContent,
              callback: function($$v) {
                _vm.ckEditorContent = $$v
              },
              expression: "ckEditorContent"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }