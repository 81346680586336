<template>
    <div style="width: 100%;">
        <div @click="showCkEditor" title="עריכה מתקדמת" class="advanced__button"><b-icon pack="fa" icon="plus"></b-icon></div>
        <div class="NewTask__Component__Editor_Inner" v-if="editor && !isCkEditor">
            <editor-content :editor="editor" />
            <div class="NewTask__Component__Editor__Actions">
                <button class="editor_button" @click="editor.chain().focus().toggleBold().run()"
                    :class="{ 'is-active': editor.isActive('bold') }">
                    <b-icon icon="format-bold"></b-icon>
                </button>
                <button class="editor_button" @click="editor.chain().focus().toggleItalic().run()"
                    :class="{ 'is-active': editor.isActive('italic') }">
                    <b-icon icon="format-italic"></b-icon>
                </button>
                <button class="editor_button" @click="editor.chain().focus().toggleUnderline().run()"
                    :class="{ 'is-active': editor.isActive('underline') }">
                    <b-icon icon="format-underline"></b-icon>
                </button>
                <button class="editor_button" @click="editor.chain().focus().unsetAllMarks().run()">
                    <b-icon icon="format-clear"></b-icon>
                </button>
            </div>
        </div>
        <ckeditor v-else-if="isCkEditor" :editor="ckEditor" v-model="ckEditorContent" :config="ckEditorConfig"> </ckeditor>
    </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import tiptapUnderline from '@tiptap/extension-underline';

const {
    ClassicEditor,
    Alignment,
    Autoformat,
    Base64UploadAdapter,
    AutoImage,
    AutoLink,
    Autosave,
    BalloonToolbar,
    BlockQuote,
    Bold,
    Bookmark,
    CloudServices,
    Code,
    Essentials,
    FontBackgroundColor,
    FontColor,
    FontFamily,
    FontSize,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HorizontalLine,
    HtmlComment,
    HtmlEmbed,
    ImageBlock,
    ImageCaption,
    ImageInline,
    ImageInsertViaUrl,
    ImageResize,
    ImageStyle,
    ImageTextAlternative,
    ImageToolbar,
    ImageUpload,
    Indent,
    IndentBlock,
    Italic,
    Link,
    LinkImage,
    List,
    ListProperties,
    //
    MediaEmbed,
    Paragraph,
    PasteFromMarkdownExperimental,
    PasteFromOffice,
    RemoveFormat,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Style,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    TextTransformation,
    TodoList,
    Underline
} = CKEDITOR;

export default {
    name: 'NewTask',
    props: {
        content: String,
        animation: {
            type: String,
            default: 'fade',
        },
        placeholder: String,
    },
    components: {
        EditorContent,
    },
    computed: {
        hasContent() {
            return this.editor && !this.editor.isEmpty;
        },
    },
    mounted() {
        const self = this;
        this.editor = new Editor({
            content: '' || this.content,
            onUpdate({ editor }) {
                if (editor.isEmpty) {
                    self.$emit('onEditorChange', '');
                } else {
                    self.$emit('onChange', editor.isEmpty);
                    self.$emit('onEditorChange', editor.getHTML());
                }
            },
            extensions: [
                StarterKit,
                tiptapUnderline,
                Placeholder.configure({
                    placeholder: this.placeholder || '',
                }),
            ],
            autofocus: true,
        });
    },
    data() {
        return {
            editor: null,
            isCkEditor: false,
            ckEditorContent: '',
            ckEditorConfig: {
                language: 'he',
                mediaEmbed: {
                    previewsInData: true
                },
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'fontSize',
                        'fontFamily',
                        'fontColor',
                        'fontBackgroundColor',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        '|',
                        'link',
                        'insertTable',
                        'highlight',
                        '|',
                        'alignment',
                        '|',
                        'bulletedList',
                        'numberedList',
                        'todoList',
                        'outdent',
                        'indent'
                    ],
                    shouldNotGroupWhenFull: false
                },
                plugins: [
                    Alignment,
                    Autoformat,
                    Base64UploadAdapter,
                    AutoImage,
                    AutoLink,
                    Autosave,
                    BalloonToolbar,
                    BlockQuote,
                    Bold,
                    Bookmark,
                    CloudServices,
                    Code,
                    Essentials,
                    FontBackgroundColor,
                    FontColor,
                    FontFamily,
                    FontSize,
                    GeneralHtmlSupport,
                    Heading,
                    Highlight,
                    HorizontalLine,
                    HtmlComment,
                    HtmlEmbed,
                    ImageBlock,
                    ImageCaption,
                    ImageInline,
                    ImageInsertViaUrl,
                    ImageResize,
                    ImageStyle,
                    ImageTextAlternative,
                    ImageToolbar,
                    ImageUpload,
                    Indent,
                    IndentBlock,
                    Italic,
                    Link,
                    LinkImage,
                    List,
                    ListProperties,
                    //
                    MediaEmbed,
                    Paragraph,
                    PasteFromMarkdownExperimental,
                    PasteFromOffice,
                    RemoveFormat,
                    SpecialCharacters,
                    SpecialCharactersArrows,
                    SpecialCharactersCurrency,
                    SpecialCharactersEssentials,
                    SpecialCharactersLatin,
                    SpecialCharactersMathematical,
                    SpecialCharactersText,
                    Strikethrough,
                    Style,
                    Subscript,
                    Superscript,
                    Table,
                    TableCaption,
                    TableCellProperties,
                    TableColumnResize,
                    TableProperties,
                    TableToolbar,
                    TextTransformation,
                    TodoList,
                    Underline
                ],
                balloonToolbar: ['bold', 'italic', '|', 'link', '|', 'bulletedList', 'numberedList'],
                fontFamily: {
                    supportAllValues: true
                },
                fontSize: {
                    options: [10, 12, 14, 'default', 18, 20, 22],
                    supportAllValues: true
                },
                heading: {
                    options: [
                        {
                            model: 'paragraph',
                            title: 'Paragraph',
                            class: 'ck-heading_paragraph'
                        },
                        {
                            model: 'heading1',
                            view: 'h1',
                            title: 'Heading 1',
                            class: 'ck-heading_heading1'
                        },
                        {
                            model: 'heading2',
                            view: 'h2',
                            title: 'Heading 2',
                            class: 'ck-heading_heading2'
                        },
                        {
                            model: 'heading3',
                            view: 'h3',
                            title: 'Heading 3',
                            class: 'ck-heading_heading3'
                        },
                        {
                            model: 'heading4',
                            view: 'h4',
                            title: 'Heading 4',
                            class: 'ck-heading_heading4'
                        },
                        {
                            model: 'heading5',
                            view: 'h5',
                            title: 'Heading 5',
                            class: 'ck-heading_heading5'
                        },
                        {
                            model: 'heading6',
                            view: 'h6',
                            title: 'Heading 6',
                            class: 'ck-heading_heading6'
                        }
                    ]
                },
                htmlSupport: {
                    allow: [
                        {
                            name: /^.*$/,
                            styles: true,
                            attributes: true,
                            classes: true
                        }
                    ]
                },
                image: {
                    toolbar: [
                        'toggleImageCaption',
                        'imageTextAlternative',
                        '|',
                        'imageStyle:inline',
                        'imageStyle:wrapText',
                        'imageStyle:breakText',
                        '|',
                        'resizeImage'
                    ]
                },
                licenseKey: 'eyJhbGciOiJFUzI1NiJ9.eyJleHAiOjE3NjcxMzkxOTksImp0aSI6IjAzMzQyMjlmLTMxYmUtNGU2MC1hNjgyLTIzOGU5YWE1M2MxMCIsInVzYWdlRW5kcG9pbnQiOiJodHRwczovL3Byb3h5LWV2ZW50LmNrZWRpdG9yLmNvbSIsImRpc3RyaWJ1dGlvbkNoYW5uZWwiOlsiY2xvdWQiLCJkcnVwYWwiXSwiZmVhdHVyZXMiOlsiRFJVUCJdLCJ2YyI6IjY5N2VlNDZmIn0.j0Qq4l_jAjCruUz_bUcn9aWq2HwWwi-ffnXv4z6aYIvjG5tapML2zQCf2ArFDo5s9qFnawNwpkE9_rXOa7gjUA',
                link: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    decorators: {
                        toggleDownloadable: {
                            mode: 'manual',
                            label: 'Downloadable',
                            attributes: {
                                download: 'file'
                            }
                        }
                    }
                },
                list: {
                    properties: {
                        styles: true,
                        startIndex: true,
                        reversed: true
                    }
                },
                menuBar: {
                    isVisible: true
                },
                placeholder: '',
                style: {
                    definitions: [
                        {
                            name: 'Article category',
                            element: 'h3',
                            classes: ['category']
                        },
                        {
                            name: 'Title',
                            element: 'h2',
                            classes: ['document-title']
                        },
                        {
                            name: 'Subtitle',
                            element: 'h3',
                            classes: ['document-subtitle']
                        },
                        {
                            name: 'Info box',
                            element: 'p',
                            classes: ['info-box']
                        },
                        {
                            name: 'Side quote',
                            element: 'blockquote',
                            classes: ['side-quote']
                        },
                        {
                            name: 'Marker',
                            element: 'span',
                            classes: ['marker']
                        },
                        {
                            name: 'Spoiler',
                            element: 'span',
                            classes: ['spoiler']
                        },
                        {
                            name: 'Code (dark)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-dark']
                        },
                        {
                            name: 'Code (bright)',
                            element: 'pre',
                            classes: ['fancy-code', 'fancy-code-bright']
                        }
                    ]
                },
                table: {
                    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
                }
            },
            ckEditor: ClassicEditor,
        };
    },
    methods: {
        close() {
            this.$emit('onClose');
        },
        save() {
            this.$emit('onSave', { html: this.editor.getHTML() });
        },
        getHTML() {
            return this.editor.getHTML();
        },
        isEmpty() {
            return this.editor.isEmpty;
        },
        clearEditor() {
            this.editor.commands.clearContent(true);
        },
        showCkEditor() {
            this.isCkEditor = !this.isCkEditor;
            if (this.isCkEditor) {
                this.ckEditorContent = this.content;
                // this.editor.commands.clearContent(true);
            }
        }
    },
    watch: {
    ckEditorContent(newValue) {
        this.$emit('onEditorChange', newValue);
    },
  },
    beforeDestroy() {
        this.editor.destroy();
    },
};
</script>

<style scoped lang="scss">
.NewTask__Component__Editor_Inner {
    background-color: #edfaff;
    padding: 0;
    border: 2px solid #2fc9e8;
    border-radius: 3px;
    margin-bottom: 10px;
    ;
}

::v-deep .ProseMirror {
    padding: 10px;
    line-height: normal;
    font-size: 15px;

    &.ProseMirror-focused {
        outline: none;
    }

    p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: right;
        font-size: 14px;
        color: #829CB9;
        line-height: 24px;
        pointer-events: none;
        height: 0;
    }
}

.advanced__button {
    cursor: pointer;
    width: 30px;

    &:hover .icon {
        background: #e0ebff;
    }
}

.NewTask__Component__Editor__Actions {
    margin: 10px 0;
    margin-bottom: 0;
    display: flex;

    .editor_button {
        border: none;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        transition: border .1s linear;

        &:hover {
            border: 1px solid silver;
        }

        ::v-deep {
            .mdi-24px::before {
                font-size: 16px;
            }
        }

        &.is-active {
            background-color: #e8e8e8;
            border: 1px solid silver;
        }
    }

}
</style>
